import { DeploymentTarget } from '@bluecodecom/types';

const DEPLOYMENT_TARGET_ENV = process.env[
  'NX_PUBLIC_DEPLOYMENT_TARGET'
] as DeploymentTarget;

class ConfigService {
  private static instance: ConfigService;

  private _deploymentTarget: DeploymentTarget =
    DEPLOYMENT_TARGET_ENV || 'bcx-dev';

  public get deploymentTarget() {
    return this._deploymentTarget;
  }

  public get isNg(): boolean {
    return this._deploymentTarget.startsWith('ng');
  }

  public get isEu(): boolean {
    return this._deploymentTarget.startsWith('bc');
  }

  public get isNotProd() {
    return !['bci-prod', 'ngc-prod'].includes(this.domain);
  }

  public get domain() {
    switch (this._deploymentTarget) {
      case 'bci-dev':
        return 'bluecode.mobi';
      case 'bci-int':
        return 'bluecode.biz';
      case 'bci-prod':
        return 'bluecode.com';
      case 'ngc-int':
        return 'pay.int.bluecode.ng';
      case 'ngc-prod':
        return 'pay.bluecode.ng';
      case 'bcx-dev':
        return 'spt-payments.mobi';
      case 'bcx-int':
        return 'spt-payments.biz';
      case 'bcx-prod':
        return 'spt-payments.com';
      case 'ngx-int':
        return 'acq.int.bluecode.ng';
      case 'ngx-prod':
        return 'acq.bluecode.ng';
    }

    throw new Error('Unknown env ' + this._deploymentTarget);
  }

  public static getInstance() {
    if (!ConfigService.instance) {
      ConfigService.instance = new ConfigService();
    }

    return ConfigService.instance;
  }
}

const configService = ConfigService.getInstance();

export default configService;
